import {
    CoreNavigationService,
    CoreSecondaryToolbarService,
    CoreTitleService,
    IBreadcrumb,
    ILevelUpLink,
    ISectionInfo,
} from '@angular-clan/core';
import {Injector, Type, inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivateFn, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {IRouteConfig, ISubPageConfig} from './route-config';
import {CommonsError, PRODUCTION_ENVIRONMENT} from '@active-agent/types';
import {DataService} from '@active-agent/app-data';
import {getObjectId} from '@active-agent/std';
import {Subscription} from 'rxjs';

interface ILayoutResolveData<TDataService extends DataService<TResult> | undefined = undefined, TResult = undefined> {
    routeConfig?: IRouteConfig<TResult>;
    dataService?: Type<TDataService>;
}

const subscriptionsByObjectId: Map<number, Subscription> = new Map();

function unsubscribeResolver(routeData: ILayoutResolveData): void {
    const objectId: number = getObjectId(routeData);
    const subscription: Subscription | undefined = subscriptionsByObjectId.get(objectId);
    if (subscription) {
        subscription.unsubscribe();
    }
}

function validateCanDeactivateConfig(route: ActivatedRouteSnapshot): void {
    const canDeactivateFunctions: Array<CanDeactivateFn<unknown>> = route.routeConfig?.canDeactivate || [];
    if (canDeactivateFunctions.length === 0) {
        throw new CommonsError(
            'missing definition for UnsubscribeLibsLayoutResolverGuard in the canDeactivate route definition',
            {data: {route}},
        );
    }
    if (canDeactivateFunctions[canDeactivateFunctions.length - 1].name !== 'libsUnsubscribeLayoutResolverGuard') {
        throw new CommonsError(
            'The last entry in the canDeactivate route definition is not of type libsUnsubscribeLayoutResolverGuard',
            {data: {route}},
        );
    }
}

function updateSections(
    config: IRouteConfig<unknown>,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    navigationService: CoreNavigationService,
    titleService: CoreTitleService,
    toolbarService: CoreSecondaryToolbarService,
    dataServiceData?: unknown,
): void {
    titleService.setData(config.getTitleData(dataServiceData, route, state));
    const sectionInfo: ISectionInfo | null = config.getSectionInfo(dataServiceData);
    navigationService.setSectionInfo(sectionInfo);

    const subPageConfig: ISubPageConfig | null = config.getSubPageConfig(dataServiceData);
    if (subPageConfig) {
        navigationService.addSubPages(subPageConfig.sectionId, subPageConfig.subPages);
    }

    const breadcrumbsConfig: Array<IBreadcrumb> = config.getBreadcrumbsConfig(dataServiceData) || [];
    navigationService.setBreadcrumbs(breadcrumbsConfig);

    const levelUpLinkConfig: ILevelUpLink | null = config.getLevelUpLink(dataServiceData);
    toolbarService.setLevelUpLink(levelUpLinkConfig);
}

const LibsLayoutResolverFn: ResolveFn<void> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): void => {
    const productionEnvironment: string | null = inject(PRODUCTION_ENVIRONMENT);
    const navigationService: CoreNavigationService = inject(CoreNavigationService);
    const titleService: CoreTitleService = inject(CoreTitleService);
    const toolbarService: CoreSecondaryToolbarService = inject(CoreSecondaryToolbarService);
    const routeData: ILayoutResolveData = route.data;
    const config: IRouteConfig | undefined = routeData.routeConfig;
    if (!config) {
        throw new Error('no valid config provided for layout resolver');
    }
    const dataService: DataService<unknown> | undefined = routeData.dataService
        ? (inject(routeData.dataService) as unknown) as DataService<unknown>
        : undefined;
    config.initInjector(inject(Injector));

    if (dataService) {
        if (!productionEnvironment && productionEnvironment !== null) {
            validateCanDeactivateConfig(route);
        }
        const id: number = getObjectId(routeData);
        const subscription: Subscription = dataService.getData().subscribe({
            next: (value: unknown) => {
                updateSections(config, route, state, navigationService, titleService, toolbarService, value);
            },
        });
        subscriptionsByObjectId.set(id, subscription);
    } else {
        updateSections(config, route, state, navigationService, titleService, toolbarService);
    }
};

export {LibsLayoutResolverFn, ILayoutResolveData, unsubscribeResolver};

