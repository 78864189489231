import {InventoryPlatform} from '../inventory/inventory-platforms';
import {Ssps} from '../ssp/ssps';

enum ViewabilityReportDimensions {
    AdvertiserId = 'ADVERTISER_ID',
    AdvertiserName = 'ADVERTISER_NAME',
    BannerId = 'BANNER_ID',
    BannerName = 'BANNER_NAME',
    BannerType = 'BANNER_TYPE',
    CampaignId = 'CAMPAIGN_ID',
    CampaignName = 'CAMPAIGN_NAME',
    OrderId = 'ORDER_ID',
    OrderName = 'ORDER_NAME',
    Hour = 'HOUR',
    Day = 'DAY',
    Month = 'MONTH',
    Year = 'YEAR',
    Ssp = 'SSP',
    TrafficType = 'TRAFFIC_TYPE',
    Deal = 'DEAL',
    DealName = 'DEAL_NAME',
    AdSlotId = 'AD_SLOT_ID',
    SupplyPartnerId = 'SUPPLY_PARTNER_ID',
    Referrer = 'REFERRER',
}

enum ViewabilityReportMetrics {
    Count = 'COUNT',
    WonCount = 'WON_COUNT',
    Contacts = 'CONTACTS',
    MonitoredAd = 'MONITORED_ADS',
    NetCost = 'NET_COST',
    AuctionPriceSum = 'AUCTION_PRICE_SUM',
    GrossAd = 'GROSS_ADS',
    PreRenderedCall = 'PRE_RENDERED_CALLS',
    NetVideoAds = 'NET_VIDEO_ADS',
    NetVideoEligibleImpression = 'NET_VIDEO_ELIGIBLE_IMPRESSIONS',
    NetVideoMeasuredImpression = 'NET_VIDEO_MEASURED_IMPRESSIONS',
    NetVideoIABViewedImpression = 'NET_VIDEO_IAB_VIEWED_IMPRESSIONS',
    VideoCompleteImpressions = 'VIDEO_COMPLETE_IMPRESSIONS',
    NetDisplayAds = 'NET_DISPLAY_ADS',
    NetDisplayMeasuredImpressions = 'NET_DISPLAY_MEASURED_IMPRESSIONS',
    NetDisplayEligibleImpressions = 'NET_DISPLAY_ELIGIBLE_IMPRESSIONS',
    NetDisplayIABViewedImpressions = 'NET_DISPLAY_IAB_VIEWED_IMPRESSIONS',
    BrandExposure1To5Seconds = 'BRAND_EXPOSURE_1_TO_5_SECONDS',
    BrandExposure5To15Seconds = 'BRAND_EXPOSURE_5_TO_15_SECONDS',
    BrandExposureOver15Seconds = 'BRAND_EXPOSURE_OVER_15_SECONDS',
}

// eslint-disable-next-line @typescript-eslint/typedef
const ViewabilityReportField = {...ViewabilityReportDimensions, ...ViewabilityReportMetrics};

// eslint-disable-next-line no-redeclare
type ViewabilityReportField = ViewabilityReportDimensions | ViewabilityReportMetrics;

interface IViewabilityReportResult {
    [ViewabilityReportField.Count]?: number;
    [ViewabilityReportField.MonitoredAd]?: number;
    [ViewabilityReportField.GrossAd]?: number;
    [ViewabilityReportField.PreRenderedCall]?: number;
    [ViewabilityReportField.NetVideoAds]?: number;
    [ViewabilityReportField.NetVideoEligibleImpression]?: number;
    [ViewabilityReportField.NetVideoMeasuredImpression]?: number;
    [ViewabilityReportField.NetVideoIABViewedImpression]?: number;
    [ViewabilityReportField.VideoCompleteImpressions]?: number;
    [ViewabilityReportDimensions.AdSlotId]?: string;
    [ViewabilityReportDimensions.Deal]?: string;
    [ViewabilityReportDimensions.DealName]?: string;
    [ViewabilityReportDimensions.TrafficType]?: InventoryPlatform;
    [ViewabilityReportDimensions.Referrer]?: string;
    [ViewabilityReportDimensions.Ssp]?: Ssps;
    [ViewabilityReportDimensions.SupplyPartnerId]?: string;
}

enum ViewabilityObjectType {
    Campaign = 'CAMPAIGN',
    Banner = 'BANNER',
    Deal = 'DEAL',
    Display = 'DISPLAY',
}

export {
    IViewabilityReportResult,
    ViewabilityObjectType,
    ViewabilityReportDimensions,
    ViewabilityReportField,
    ViewabilityReportMetrics,
};
