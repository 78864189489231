import {
    Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ChangeDetectorRef,
} from '@angular/core';
import {LibsConfirmationDialogComponent, IDialogConfirmationData} from '../../confirmation-dialog/confirmation-dialog.component';
import {LibsSpacerSize} from '../../spacer/spacer.component';
import {CommonsFileService, IImageSize} from '@active-agent/file';
import Cropper from 'cropperjs';
import {IAddCustomSizeOnSubmitData} from './add-custom-size-popover.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSelectionListChange} from '@angular/material/list';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {PopupTrigger} from '@angular-clan/core/popup';

@Component({
    selector: 'ad-image-selection-cropper',
    templateUrl: 'image-selection-cropper.html',
    styleUrls: ['./image-selection-cropper.scss'],
})

class AdImageSelectionCropperComponent implements OnChanges {
    @Input() public image: File | null = null;
    @Input() public availableSizes: Array<IAvailableSizesGroup> = [];

    public closePopup: boolean = false;

    public isEditorVisible: boolean = false;
    public base64Image: string | null = null;
    public selectedSizes: Array<IImageSize> = [];
    public currentCropPosition: ICropPosition = {x: 0, y: 0};
    public availableSizeConfigurations: Array<IAvailableSizesGroup> = [];
    public spacerSize: LibsSpacerSize = LibsSpacerSize.Small;
    public trigger: PopupTrigger = PopupTrigger.Click;
    public imageFileDimensions: IImageSize | null = null;

    @Output() private onFileChange: EventEmitter<IFileChangeEvent> = new EventEmitter();
    @Output() private onCancel: EventEmitter<void> = new EventEmitter();
    @ViewChild('cropper', {static: false}) private cropperElement!: ElementRef;
    private editorCropperInstance: Cropper | null = null;
    private previewCropperInstancesBySize: Map<IImageSize, Cropper> = new Map();
    private hasCropPositionChanged: boolean = false;

    constructor(
        private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.availableSizes && changes.availableSizes.currentValue !== undefined) {
            this.availableSizeConfigurations = [
                {
                    title: $localize`:@@IMAGE_SELECTION_CROPPER_CUSTOM_GROUP_TITLE:IMAGE_SELECTION_CROPPER_CUSTOM_GROUP_TITLE`,
                    sizes: [],
                },
            ].concat(changes.availableSizes.currentValue);
            this.availableSizeConfigurations.forEach((configuration: IAvailableSizesGroup) => {
                if (configuration.expanded === undefined) {
                    configuration.expanded = true;
                }
            });
        }

        if (changes.image && changes.image.currentValue !== undefined) {
            this.selectedSizes = [];
            this.availableSizeConfigurations.forEach((config: IAvailableSizesGroup) => {
                config.sizes.forEach((current: IAvailableSize) => current.selected = false);
            });
            CommonsFileService.readFileAsBase64(changes.image.currentValue)
                .then((base64Image: string) => {
                    this.base64Image = base64Image;

                    CommonsFileService.getImageDimensions(this.base64Image)
                        .then((size: IImageSize) => {
                            this.imageFileDimensions = size;
                            this.currentCropPosition = {
                                x: size.width / 2,
                                y: size.height / 2,
                            };
                            this.addSize(size);

                            // Always add the original size to the custom sizes
                            this.availableSizeConfigurations[0].sizes.push({
                                dimensions: size,
                                selected: true,
                            });

                            this.changeAllSizesValues(size, true);
                        });
                });
        }
    }

    public toggleEditor(): void {
        this.isEditorVisible = !this.isEditorVisible;

        if (this.isEditorVisible) {
            setTimeout(() => {
                this.initCropper();
            });
        }
    }

    public addFiles(): void {
        const files: Array<string> = Array.from(this.previewCropperInstancesBySize.entries())
            .filter(([size]: [IImageSize, Cropper]) => {
                return this.selectedSizes.includes(size);
            })
            .map(([, cropper]: [IImageSize, Cropper]) => {
                if (this.image) {
                    return cropper.getCroppedCanvas().toDataURL(this.image.type);
                }

                return cropper.getCroppedCanvas().toDataURL();
            });

        const event: IFileChangeEvent = {
            files,
            originalSize: this.isOriginalImageSizeSelected(),
            originalPosition: !this.hasCropPositionChanged,
            selectedSizes: this.selectedSizes,
        };

        this.onFileChange.emit(event);
    }

    public isSizeDisabled(dimension: IImageSize): boolean {
        if (!this.imageFileDimensions) {
            return true;
        }

        return dimension.width > this.imageFileDimensions.width
            || dimension.height > this.imageFileDimensions.height;
    }

    public isOriginalImageSize(dimension: IImageSize): boolean {
        if (!this.imageFileDimensions) {
            return true;
        }

        return dimension.width === this.imageFileDimensions.width
            && dimension.height === this.imageFileDimensions.height;
    }

    public isOriginalImageSizeSelected(): boolean {
        return this.selectedSizes.some((size: IImageSize) => {
            return this.isOriginalImageSize(size);
        });
    }

    public onSizeSelectionChange(changeEvent: MatSelectionListChange, configuration: IAvailableSizesGroup): void {
        const value: IAvailableSize = changeEvent.options[0].value;
        const dimensions: IImageSize = value.dimensions;
        const selected: boolean = changeEvent.options[0].selected;
        if (this.isOriginalImageSize(dimensions) && selected && this.selectedSizes.length > 0) {
            const dialogData: IDialogConfirmationData = {
                title: $localize`:@@IMAGE_SELECTION_CROPPER_SELECT_ORIGINAL_SIZE_DIALOG_TITLE:IMAGE_SELECTION_CROPPER_SELECT_ORIGINAL_SIZE_DIALOG_TITLE`,
                description: $localize`:@@IMAGE_SELECTION_CROPPER_SELECT_ORIGINAL_SIZE_DIALOG_DESCRIPTION:IMAGE_SELECTION_CROPPER_SELECT_ORIGINAL_SIZE_DIALOG_DESCRIPTION`,
            };

            this.dialog
                .open<LibsConfirmationDialogComponent, IDialogConfirmationData>(LibsConfirmationDialogComponent, {data: dialogData})
                .afterClosed()
                .subscribe((confirmed: boolean) => {
                    if (!confirmed) {
                        value.selected = false;
                        const index: number = configuration.sizes.findIndex((current: IAvailableSize) => {
                            return current === value;
                        });

                        if (index > -1) {
                            configuration.sizes.splice(index, 1, {...value});
                        }
                    } else {
                        this.addSize(dimensions);
                        this.changeAllSizesValues(dimensions, selected);
                        this.updateEditorCropperSize();
                        this.isEditorVisible = false;
                    }

                });

            return;
        }

        this.changeAllSizesValues(dimensions, selected);
        if (selected) {
            this.addSize(dimensions);
        } else {
            this.removeSize(dimensions);
        }

        this.updateEditorCropperSize();
    }

    public onPreviewCropperInit(cropperInstance: Cropper, size: IImageSize): void {
        this.previewCropperInstancesBySize.set(size, cropperInstance);
    }

    public cancel(): void {
        this.onCancel.emit();
    }

    public removeItemFromPreview(size: IImageSize): void {
        this.changeAllSizesValues(size, false);
        this.removeSize(size);
        this.updateEditorCropperSize();
    }

    public onAddCustomSize(data: IAddCustomSizeOnSubmitData): void {
        const width: number = data.width;
        const height: number = data.height;
        const size: IImageSize = {width, height};

        const isExistingSize: boolean = !!this.availableSizeConfigurations
            .find((config: IAvailableSizesGroup) => {
                return !!config.sizes.find((current: IAvailableSize) => {
                    return current.dimensions.width === size.width
                        && current.dimensions.height === size.height;
                });
            });
        if (!isExistingSize) {
            this.availableSizeConfigurations[0].sizes.push({
                dimensions: size,
                selected: true,
            });
        } else {
            this.changeAllSizesValues(size, true);
        }

        const isSelectedSize: boolean = !!this.selectedSizes.find((current: IImageSize) => {
            return current.width === size.width
                && current.height === size.height;
        });
        if (!isSelectedSize) {
            this.addSize(size);
        }

        this.updateEditorCropperSize();
        this.closePopup = true;
    }

    public isAtLeastOneItemInGroupSelected(group: IAvailableSizesGroup): boolean {
        return group.sizes.some((value: IAvailableSize) => value.selected);
    }

    public areAllItemsInGroupSelected(group: IAvailableSizesGroup): boolean {
        if (!group.sizes.length) {
            return false;
        }

        return group.sizes.every((value: IAvailableSize) => value.selected);
    }

    public areAllItemsInGroupDisabled(group: IAvailableSizesGroup): boolean {
        if (!group.sizes.length) {
            return false;
        }

        return group.sizes.every((value: IAvailableSize) => this.isSizeDisabled(value.dimensions));
    }

    public onGroupChange(group: IAvailableSizesGroup, changeEvent: MatCheckboxChange): void {
        const selected: boolean = changeEvent.checked;
        group.sizes
            .filter((size: IAvailableSize) => !this.isSizeDisabled(size.dimensions))
            .forEach((size: IAvailableSize) => {
                this.changeAllSizesValues(size.dimensions, selected);
                if (selected) {
                    this.addSize(size.dimensions);
                } else {
                    this.removeSize(size.dimensions);
                }

                this.updateEditorCropperSize();
            });
    }

    public toggleGroup(group: IAvailableSizesGroup): void {
        group.expanded = group.expanded === undefined ? true : !group.expanded;
    }

    private updateEditorCropperSize(): void {
        if (!this.editorCropperInstance || !this.imageFileDimensions) {
            return;
        }
        const width: number = Math.max(...this.selectedSizes.map((size: IImageSize) => size.width));
        const height: number = Math.max(...this.selectedSizes.map((size: IImageSize) => size.height));
        let x: number = this.currentCropPosition.x;
        let y: number = this.currentCropPosition.y;
        if (x - (width / 2) < 0) {
            x = width / 2;
        }
        if (x + (width / 2) > this.imageFileDimensions.width) {
            x = this.imageFileDimensions.width - (width / 2);
        }
        if (y - (height / 2) < 0) {
            y = height / 2;
        }
        if (y + (height / 2) > this.imageFileDimensions.height) {
            y = this.imageFileDimensions.height - (height / 2);
        }
        this.currentCropPosition = {x, y};
        this.editorCropperInstance.setData({
            x: this.currentCropPosition.x - (width / 2),
            y: this.currentCropPosition.y - (height / 2),
            width,
            height,
        });
    }

    private removeSize(size: IImageSize): void {
        const index: number = this.selectedSizes.findIndex((current: IImageSize) => {
            return current.width === size.width && current.height === size.height;
        });
        if (index > -1) {
            this.selectedSizes.splice(index, 1);
        }
    }

    private addSize(size: IImageSize): void {
        const index: number = this.selectedSizes.findIndex((current: IImageSize) => {
            return current.width === size.width && current.height === size.height;
        });
        if (index === -1) {
            this.selectedSizes.push(size);

            // Prevents of not showing the preview image
            this.changeDetector.detectChanges();
        }
    }

    private initCropper(): void {
        const currentPosition: ICropPosition = this.currentCropPosition;
        const width: number = Math.max(...this.selectedSizes.map((size: IImageSize) => size.width));
        const height: number = Math.max(...this.selectedSizes.map((size: IImageSize) => size.height));
        this.editorCropperInstance = new Cropper(
            this.cropperElement.nativeElement,
            {
                movable: false,
                zoomable: false,
                scalable: false,
                rotatable: false,
                cropBoxResizable: false,
                toggleDragModeOnDblclick: false,
                dragMode: 'move',
                viewMode: 1,
                cropmove: (): void => {
                    if (!this.editorCropperInstance) {
                        return;
                    }
                    if (!this.hasCropPositionChanged) {
                        this.hasCropPositionChanged = true;
                    }
                    const data: Cropper.Data = this.editorCropperInstance.getData();
                    this.currentCropPosition = {
                        x: data.x + (data.width / 2),
                        y: data.y + (data.height / 2),
                    };
                    this.changeDetector.detectChanges();
                },
                // eslint-disable-next-line object-shorthand
                ready: function(_event: CustomEvent<any>): void {
                    this.cropper
                        .setData({
                            x: currentPosition.x - (width / 2),
                            y: currentPosition.y - (height / 2),
                            width,
                            height,
                        });
                },
            },
        );
    }

    private changeAllSizesValues(imageSize: IImageSize, value: boolean): void {
        this.availableSizeConfigurations.forEach((configuration: IAvailableSizesGroup) => {
            configuration.sizes.forEach((current: IAvailableSize) => {
                if (
                    imageSize.width === current.dimensions.width
                    && imageSize.height === current.dimensions.height
                ) {
                    current.selected = value;
                }

            });
        });
    }
}

interface ICropPosition {
    x: number;
    y: number;
}

interface IAvailableSize {
    dimensions: IImageSize;
    selected: boolean;
    translation?: string;
}

interface IAvailableSizesGroup {
    sizes: Array<IAvailableSize>;
    title: string;
    expanded?: boolean;
}

interface IFileChangeEvent {
    // base64Images
    files: Array<string>;
    originalPosition: boolean;
    originalSize: boolean;
    selectedSizes: Array<IImageSize>;
}

export {AdImageSelectionCropperComponent, ICropPosition, IAvailableSizesGroup, IFileChangeEvent};
