<mat-expansion-panel
    class="expansion-panel"
    [(expanded)]="expanded"
    [disabled]="disabled"
    (opened)="opened()"
    (closed)="closed()"
    [class.expansion-panel--header-highlighted]="highlightWhenOpened"
    [class.expansion-panel--header-multi-line]="subtitle"
>
    <!-- Panel Header -->
    <mat-expansion-panel-header>
        <div class="expansion-panel__header">
            <commons-status-icon
                class="expansion-panel__status-icon"
                [class.expansion-panel__status-icon--disabled]="!isActive"
                [status]="status"
                [cursor]="cursorPointer"
            ></commons-status-icon>

            <div class="expansion-panel__title"  [class.expansion-panel__title--single-line]="!subtitle">
                {{title}}
            </div>

            <div *ngIf="subtitle" class="expansion-panel__subtitle">{{subtitle}}</div>

            <!-- switch -->
            <div
                *ngIf="showSwitch"
                class="expansion-panel__switch"
                [matTooltip]="switchTooltip || ''"
                [matTooltipDisabled]="switchTooltip === null"
                matTooltipClass="tooltip"
            >
                <div
                    *ngIf="switchLabel"
                    class="expansion-panel__switch-label"
                    [class.expansion-panel__switch-label--disabled]="!activateSwitch"
                >
                    {{switchLabel}}
                </div>
                <mat-slide-toggle
                    color="primary"
                    [disableRipple]="true"
                    (click)="switchClicked($event)"
                    (change)="switchChanged($event)"
                    [disabled]="disableSwitch"
                    [checked]="activateSwitch"
                ></mat-slide-toggle>
            </div>
        </div>
    </mat-expansion-panel-header>
    <div
        class="expansion-panel__content"
        [class.expansion-panel__content--with-shadow]="highlightWhenOpened"
    >
        <ng-content></ng-content>
    </div>
</mat-expansion-panel>
