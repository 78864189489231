import {FilterTypes} from '../types/filter.types';
import {IasFilter, IasFilterProperties} from '../ias/ias-filter';

type IasSiteFilterProperties = IasFilterProperties;

class IasSiteFilter extends IasFilter<FilterTypes.IAS_SITE_CONTEXTUAL> {
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _type: FilterTypes.IAS_SITE_CONTEXTUAL = FilterTypes.IAS_SITE_CONTEXTUAL;
    protected readonly props: IasSiteFilterProperties = this.getDefaultOptions();
}

export {IasSiteFilter};

