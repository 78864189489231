import {isObservable, Observable} from 'rxjs';
import {isPromise} from '../utils';

const valueByObservable: WeakMap<Observable<unknown> | Promise<unknown>, unknown>
    = new WeakMap<Observable<unknown> | Promise<unknown>, unknown>();
function storeResolvedObservableValue(
    observable: Observable<unknown> | Promise<unknown>,
    value: unknown,
): void {
    valueByObservable.set(observable, value);
}

function getStoredResolvedObservableValue(observable: Observable<unknown> | Promise<unknown>): unknown {
    return valueByObservable.get(observable);
}

function getObservableNumberComparator(): (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: boolean) => number {
    return (valueA: any, valueB: any, _nodeA: any, _nodeB: any) => {
        if (isObservable(valueA) || isObservable(valueB) || isPromise(valueA) || isPromise(valueB)) {
            return (getStoredResolvedObservableValue(valueA) as number || 0) - (getStoredResolvedObservableValue(valueB) as number || 0);
        }

        return valueA - valueB;
    };
}

export {
    getObservableNumberComparator,
    storeResolvedObservableValue,
    getStoredResolvedObservableValue,
};
