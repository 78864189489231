import {Injectable, Signal} from '@angular/core';
import {DataService} from './data-service';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
class FilterScreenHandlerService extends DataService<IFilterData> {

    readonly #isFullScreen: Signal<boolean>;

    constructor() {
        super({isFullScreen: false});

        this.#isFullScreen = toSignal(
            this.getData().pipe(
                map((data: IFilterData) => data.isFullScreen),
            ),
            {requireSync: true},
        );
    }

    public isFullScreen(): Signal<boolean> {
        return this.#isFullScreen;
    }
}

interface IFilterData {
    isFullScreen: boolean;
}

export {FilterScreenHandlerService, IFilterData};
