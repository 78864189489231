import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {CoreCookieService} from '@angular-clan/core';
import {manualLinkIds, ManualLinkKey} from './manual-link-keys.config';
import {CommonsError} from '@active-agent/types';

@Injectable({
    providedIn: 'root',
})
class LibsManualService {
    private readonly manualDomain: string = 'https://help.active-agent.com';
    constructor(
        private cookieService: CoreCookieService,
    ) {}

    public hasManual(): Observable<boolean> {
        return of(true);
    }

    public openPage(key: ManualLinkKey): Observable<void> {
        const manualLinkId: number | undefined = manualLinkIds[key];

        if (manualLinkId === undefined) {
            return throwError(() => new CommonsError(
                'manual link id not found',
                {data: {key}},
            ));
        }

        return new Observable((): void => {
            const token: string | null = this.cookieService.get('token');

            const url: string = `${this.manualDomain}/${manualLinkId}`;
            if (!token) {
                window.open(url, '_blank');

                return;
            }

            const manualForm: HTMLFormElement = document.createElement('form');
            manualForm.setAttribute('target', '_blank');
            manualForm.setAttribute('method', 'POST');
            manualForm.setAttribute('action', url);

            const params: Record<string, string> = {
                token,
            };
            Object.keys(params).forEach((paramKey: string): void => {
                const value: string = params[paramKey];

                const input: HTMLInputElement = document.createElement('input');
                input.type = 'hidden';
                input.name = paramKey;
                input.value = value;
                manualForm.appendChild(input);
            });

            document.body.appendChild(manualForm);
            manualForm.submit();
            document.body.removeChild(manualForm);
        });
    }

    public linkExists(key: ManualLinkKey): Observable<boolean> {
        return of(manualLinkIds[key] !== undefined);
    }
}

export {LibsManualService};
