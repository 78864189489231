import {CommonsError, FilterTypes, IIcon} from '@active-agent/types';
import {parseMarkdown} from '@active-agent/markdown';

type FilterTypesForTranslations = Exclude<
FilterTypes,
FilterTypes.CAMPAIGN_ADDRESSABLE_TV_TRAFFIC_TYPE |
FilterTypes.CAMPAIGN_PEP_SSP |
FilterTypes.CAMPAIGN_DOOH_TRAFFIC_TYPE |
FilterTypes.UNSUPPORTED |
FilterTypes.SEVEN_ONE_AUDIENCES |
FilterTypes.REDIRECT_CONTENT_UNIT |
FilterTypes.GENERIC
>;

const TYPE_TRANSLATIONS: Record<FilterTypesForTranslations, string> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]: $localize`:@@ADSQUARE_GEO_FILTER_NAME:ADSQUARE_GEO_FILTER_NAME`,
    [FilterTypes.AUCTION_TYPE]: $localize`:@@AUCTION_TYPE_FILTER_NAME:AUCTION_TYPE_FILTER_NAME`,
    [FilterTypes.AUDIO]: $localize`:@@AUDIO_TARGETING_NAME:AUDIO_TARGETING_NAME`,
    [FilterTypes.BROWSER]: $localize`:@@BROWSER:BROWSER`,
    [FilterTypes.CAPPING]: $localize`:@@FILTER_TYPE_FREQUENCY_CAPPING_NAME:FILTER_TYPE_FREQUENCY_CAPPING_NAME`,
    [FilterTypes.CITY]: $localize`:@@CITY_FILTER_NAME:CITY_FILTER_NAME`,
    [FilterTypes.CLUSTER]: $localize`:@@CLUSTER:CLUSTER`,
    [FilterTypes.CONTENT_CATEGORY]: $localize`:@@CONTENT_CATEGORY_TARGETING_NAME:CONTENT_CATEGORY_TARGETING_NAME`,
    [FilterTypes.COUNTRY]: $localize`:@@COUNTRIES:COUNTRIES`,
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]: $localize`:@@CUSTOM_SITE_CONTEXTUAL_FILTER_NAME:CUSTOM_SITE_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.DOMAIN]: $localize`:@@DOMAIN_FILTER_NAME:DOMAIN_FILTER_NAME`,
    [FilterTypes.DOOH_LOCATION]: $localize`:@@DOOH_LOCATION_FILTER_NAME:DOOH_LOCATION_FILTER_NAME`,
    [FilterTypes.GRAPH_CAPPING]: $localize`:@@FILTER_TYPE_GRAPH_CAPPING_NAME:FILTER_TYPE_GRAPH_CAPPING_NAME`,
    [FilterTypes.IAS_APP_CONTEXTUAL]: $localize`:@@IAS_APP_FILTER_NAME:IAS_APP_FILTER_NAME`,
    [FilterTypes.IAS_SITE_CONTEXTUAL]: $localize`:@@IAS_WEB_FILTER_NAME:IAS_WEB_FILTER_NAME`,
    [FilterTypes.INTERSTITIAL]: $localize`:@@INTERSTITIAL_FILTER_NAME:INTERSTITIAL_FILTER_NAME`,
    [FilterTypes.LANGUAGE]: $localize`:@@LANGUAGE_TARGETING_NAME:LANGUAGE_TARGETING_NAME`,
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: $localize`:@@ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_NAME:ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: $localize`:@@ODC_CONTEXT_CONTEXTUAL_FILTER_NAME:ODC_CONTEXT_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: $localize`:@@ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_NAME:ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: $localize`:@@ODC_LANGUAGE_CONTEXTUAL_FILTER_NAME:ODC_LANGUAGE_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: $localize`:@@ODC_VIEWABILITY_CONTEXTUAL_FILTER_NAME:ODC_VIEWABILITY_CONTEXTUAL_FILTER_NAME`,
    [FilterTypes.OS]: $localize`:@@OPERATING_SYSTEM:OPERATING_SYSTEM`,
    [FilterTypes.OTTO_PNTA_CUSTOM]: $localize`:@@OTTO_PNTA_CUSTOM_FILTER_NAME:OTTO_PNTA_CUSTOM_FILTER_NAME`,
    [FilterTypes.OTTO_PNTA_GEO]: $localize`:@@OTTO_PNTA_GEO_FILTER_NAME:OTTO_PNTA_GEO_FILTER_NAME`,
    [FilterTypes.OTTO_PNTA_INTENT]: $localize`:@@OTTO_PNTA_INTENT_FILTER_NAME:OTTO_PNTA_INTENT_FILTER_NAME`,
    [FilterTypes.OTTO_PNTA_PURCHASE]: $localize`:@@OTTO_PNTA_PURCHASE_FILTER_NAME:OTTO_PNTA_PURCHASE_FILTER_NAME`,
    [FilterTypes.OTTO_PNTA_SOZIO]: $localize`:@@OTTO_PNTA_SOZIO_FILTER_NAME:OTTO_PNTA_SOZIO_FILTER_NAME`,
    [FilterTypes.PLATFORM]: $localize`:@@PLATFORM:PLATFORM`,
    [FilterTypes.POLYGON]: $localize`:@@POLYGON_FILTER_NAME:POLYGON_FILTER_NAME`,
    [FilterTypes.POSTAL_CODE]: $localize`:@@POSTAL_CODE_FILTER_NAME:POSTAL_CODE_FILTER_NAME`,
    [FilterTypes.PROFILE]: $localize`:@@PROFILE_TARGETING_HEADLINE:PROFILE_TARGETING_HEADLINE`,
    [FilterTypes.PROGRAMMATIC_TV_CHANNEL]: $localize`:@@PROGRAMMATIC_TV_CHANNEL_FILTER_NAME:PROGRAMMATIC_TV_CHANNEL_FILTER_NAME`,
    [FilterTypes.PROVIDER]: $localize`:@@PROVIDER_FILTER_NAME:PROVIDER_FILTER_NAME`,
    [FilterTypes.SSP]: $localize`:@@SSP:SSP`,
    [FilterTypes.STATE]: $localize`:@@STATES:STATES`,
    [FilterTypes.TAGGING]: $localize`:@@TAGGING_TARGETING_HEADLINE:TAGGING_TARGETING_HEADLINE`,
    [FilterTypes.TIME]: $localize`:@@TIME_TARGETING_NAME:TIME_TARGETING_NAME`,
    [FilterTypes.TV_CHANNEL]: $localize`:@@TV_CHANNEL_FILTER_NAME:TV_CHANNEL_FILTER_NAME`,
    [FilterTypes.TV_TARGETING_GROUP]: $localize`:@@TV_TARGETING_GROUP:TV_TARGETING_GROUP`,
    [FilterTypes.URL]: $localize`:@@URL:URL`,
    [FilterTypes.VIDEO_PLAYER]: $localize`:@@VIDEO_PLAYER_FILTER_NAME:VIDEO_PLAYER_FILTER_NAME`,
    [FilterTypes.VIDEO_POSITION]: $localize`:@@VIDEO_POSITION_FILTER_NAME:VIDEO_POSITION_FILTER_NAME`,
    [FilterTypes.VISIBILITY]: $localize`:@@VISIBILITY_TARGETING_NAME:VISIBILITY_TARGETING_NAME`,
    [FilterTypes.WEATHER]: $localize`:@@WEATHER_FILTER_NAME:WEATHER_FILTER_NAME`,
    [FilterTypes.WIFI]: $localize`:@@WIFI_FILTER_NAME:WIFI_FILTER_NAME`,
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: $localize`:@@WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_NAME:WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_NAME`,
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: $localize`:@@WEISCHER_AUDIENCES_TRACE_FILTER_NAME:WEISCHER_AUDIENCES_TRACE_FILTER_NAME`,
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: $localize`:@@WEISCHER_AUDIENCES_CUSTOM_FILTER_NAME:WEISCHER_AUDIENCES_CUSTOM_FILTER`,
};

const TYPE_TRANSLATIONS_INVENTORIED: Partial<Record<FilterTypes, string>> = {
    [FilterTypes.CAPPING]: $localize`:@@FILTER_TYPE_FREQUENCY_CAPPING_INVENTORIED:FILTER_TYPE_FREQUENCY_CAPPING_INVENTORIED`,
    [FilterTypes.GRAPH_CAPPING]: $localize`:@@FILTER_TYPE_GRAPH_CAPPING_INVENTORIED_NAME:FILTER_TYPE_GRAPH_CAPPING_INVENTORIED_NAME`,
};

const TYPE_TRANSLATIONS_NAVIGATION: Partial<Record<FilterTypes, string>> = {
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: $localize`:@@ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_NAVIGATION_NAME:ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_NAVIGATION_NAME`,
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: $localize`:@@ODC_CONTEXT_CONTEXTUAL_FILTER_NAVIGATION_NAME:ODC_CONTEXT_CONTEXTUAL_FILTER_NAVIGATION_NAME`,
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: $localize`:@@ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_NAVIGATION_NAME:ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_NAVIGATION_NAME`,
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: $localize`:@@ODC_LANGUAGE_CONTEXTUAL_FILTER_NAVIGATION_NAME:ODC_LANGUAGE_CONTEXTUAL_FILTER_NAVIGATION_NAME`,
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: $localize`:@@ODC_VIEWABILITY_CONTEXTUAL_FILTER_NAVIGATION_NAME:ODC_VIEWABILITY_CONTEXTUAL_FILTER_NAVIGATION_NAME`,
    [FilterTypes.OTTO_PNTA_CUSTOM]: $localize`:@@OTTO_PNTA_CUSTOM_FILTER_NAVIGATION_NAME:OTTO_PNTA_CUSTOM_FILTER_NAVIGATION_NAME`,
    [FilterTypes.OTTO_PNTA_GEO]: $localize`:@@OTTO_PNTA_GEO_FILTER_NAVIGATION_NAME:OTTO_PNTA_GEO_FILTER_NAVIGATION_NAME`,
    [FilterTypes.OTTO_PNTA_INTENT]: $localize`:@@OTTO_PNTA_INTENT_FILTER_NAVIGATION_NAME:OTTO_PNTA_INTENT_FILTER_NAVIGATION_NAME`,
    [FilterTypes.OTTO_PNTA_PURCHASE]: $localize`:@@OTTO_PNTA_PURCHASE_FILTER_NAVIGATION_NAME:OTTO_PNTA_PURCHASE_FILTER_NAVIGATION_NAME`,
    [FilterTypes.OTTO_PNTA_SOZIO]: $localize`:@@OTTO_PNTA_SOZIO_FILTER_NAVIGATION_NAME:OTTO_PNTA_SOZIO_FILTER_NAVIGATION_NAME`,
};

const TYPE_TRANSLATIONS_PREVIEW: Partial<Record<FilterTypes, string>> = {
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: $localize`:@@ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_PREVIEW_NAME:ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_PREVIEW_NAME`,
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: $localize`:@@ODC_CONTEXT_CONTEXTUAL_FILTER_PREVIEW_NAME:ODC_CONTEXT_CONTEXTUAL_FILTER_PREVIEW_NAME`,
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: $localize`:@@ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_PREVIEW_NAME:ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_PREVIEW_NAME`,
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: $localize`:@@ODC_LANGUAGE_CONTEXTUAL_FILTER_PREVIEW_NAME:ODC_LANGUAGE_CONTEXTUAL_FILTER_PREVIEW_NAME`,
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: $localize`:@@ODC_VIEWABILITY_CONTEXTUAL_FILTER_PREVIEW_NAME:ODC_VIEWABILITY_CONTEXTUAL_FILTER_PREVIEW_NAME`,
    [FilterTypes.OTTO_PNTA_CUSTOM]: $localize`:@@OTTO_PNTA_CUSTOM_FILTER_PREVIEW_NAME:OTTO_PNTA_CUSTOM_FILTER_PREVIEW_NAME`,
    [FilterTypes.OTTO_PNTA_GEO]: $localize`:@@OTTO_PNTA_GEO_FILTER_PREVIEW_NAME:OTTO_PNTA_GEO_FILTER_PREVIEW_NAME`,
    [FilterTypes.OTTO_PNTA_INTENT]: $localize`:@@OTTO_PNTA_INTENT_FILTER_PREVIEW_NAME:OTTO_PNTA_INTENT_FILTER_PREVIEW_NAME`,
    [FilterTypes.OTTO_PNTA_PURCHASE]: $localize`:@@OTTO_PNTA_PURCHASE_FILTER_PREVIEW_NAME:OTTO_PNTA_PURCHASE_FILTER_PREVIEW_NAME`,
    [FilterTypes.OTTO_PNTA_SOZIO]: $localize`:@@OTTO_PNTA_SOZIO_FILTER_PREVIEW_NAME:OTTO_PNTA_SOZIO_FILTER_PREVIEW_NAME`,
};

const TYPE_TRANSLATIONS_PREVIEW_INVENTORIED: Partial<Record<FilterTypes, string>> = {};

const HELP_TRANSLATIONS: Record<FilterTypesForTranslations, string> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]: $localize`:@@ADSQUARE_GEO_FILTER_HELP:ADSQUARE_GEO_FILTER_HELP`,
    [FilterTypes.AUCTION_TYPE]: $localize`:@@AUCTION_TYPE_FILTER_HELP:AUCTION_TYPE_FILTER_HELP`,
    [FilterTypes.AUDIO]: $localize`:@@AUDIO_TARGETING_HELP:AUDIO_TARGETING_HELP`,
    [FilterTypes.BROWSER]: $localize`:@@BROWSER_TARGETING_HELP:BROWSER_TARGETING_HELP`,
    [FilterTypes.CAPPING]: $localize`:@@FILTER_TYPE_FREQUENCY_CAPPING_HELP:FILTER_TYPE_FREQUENCY_CAPPING_HELP`,
    [FilterTypes.CITY]: $localize`:@@CITY_FILTER_HELP:CITY_FILTER_HELP`,
    [FilterTypes.CLUSTER]: $localize`:@@CLUSTER_TARGETING_HELP:CLUSTER_TARGETING_HELP`,
    [FilterTypes.CONTENT_CATEGORY]: $localize`:@@CONTENT_CATEGORY_TARGETING_HELP:CONTENT_CATEGORY_TARGETING_HELP`,
    [FilterTypes.COUNTRY]: $localize`:@@COUNTRY_TARGETING_HELP:COUNTRY_TARGETING_HELP`,
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]: $localize`:@@CUSTOM_SITE_CONTEXTUAL_FILTER_HELP:CUSTOM_SITE_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.DOMAIN]: $localize`:@@DOMAIN_FILTER_HELP:DOMAIN_FILTER_HELP`,
    [FilterTypes.DOOH_LOCATION]: $localize`:@@DOOH_LOCATION_FILTER_HELP:DOOH_LOCATION_FILTER_HELP`,
    [FilterTypes.GRAPH_CAPPING]: $localize`:@@FILTER_TYPE_GRAPH_CAPPING_HELP:FILTER_TYPE_GRAPH_CAPPING_HELP`,
    [FilterTypes.IAS_APP_CONTEXTUAL]: $localize`:@@IAS_APP_FILTER_HELP:IAS_APP_FILTER_HELP`,
    [FilterTypes.IAS_SITE_CONTEXTUAL]: $localize`:@@IAS_WEB_FILTER_HELP:IAS_WEB_FILTER_HELP`,
    [FilterTypes.INTERSTITIAL]: $localize`:@@INTERSTITIAL_FILTER_HELP:INTERSTITIAL_FILTER_HELP`,
    [FilterTypes.LANGUAGE]: $localize`:@@LANGUAGE_TARGETING_HELP:LANGUAGE_TARGETING_HELP`,
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: $localize`:@@ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_HELP:ODC_BRAND_SAFETY_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: $localize`:@@ODC_CONTEXT_CONTEXTUAL_FILTER_HELP:ODC_CONTEXT_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: $localize`:@@ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_HELP:ODC_INVALID_TRAFFIC_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: $localize`:@@ODC_LANGUAGE_CONTEXTUAL_FILTER_HELP:ODC_LANGUAGE_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: $localize`:@@ODC_VIEWABILITY_CONTEXTUAL_FILTER_HELP:ODC_VIEWABILITY_CONTEXTUAL_FILTER_HELP`,
    [FilterTypes.OS]: $localize`:@@OPERATING_SYSTEM_TARGETING_HELP:OPERATING_SYSTEM_TARGETING_HELP`,
    [FilterTypes.OTTO_PNTA_CUSTOM]: $localize`:@@OTTO_PNTA_CUSTOM_FILTER_HELP:OTTO_PNTA_CUSTOM_FILTER_HELP`,
    [FilterTypes.OTTO_PNTA_GEO]: $localize`:@@OTTO_PNTA_GEO_FILTER_HELP:OTTO_PNTA_GEO_FILTER_HELP`,
    [FilterTypes.OTTO_PNTA_INTENT]: $localize`:@@OTTO_PNTA_INTENT_FILTER_HELP:OTTO_PNTA_INTENT_FILTER_HELP`,
    [FilterTypes.OTTO_PNTA_PURCHASE]: $localize`:@@OTTO_PNTA_PURCHASE_FILTER_HELP:OTTO_PNTA_PURCHASE_FILTER_HELP`,
    [FilterTypes.OTTO_PNTA_SOZIO]: $localize`:@@OTTO_PNTA_SOZIO_FILTER_HELP:OTTO_PNTA_SOZIO_FILTER_HELP`,
    [FilterTypes.PLATFORM]: $localize`:@@PLATFORM_TARGETING_HELP:PLATFORM_TARGETING_HELP`,
    [FilterTypes.POLYGON]: $localize`:@@POLYGON_FILTER_HELP:POLYGON_FILTER_HELP`,
    [FilterTypes.POSTAL_CODE]: $localize`:@@POSTAL_CODE_FILTER_HELP:POSTAL_CODE_FILTER_HELP`,
    [FilterTypes.PROFILE]: $localize`:@@PROFILE_TARGETING_HELP:PROFILE_TARGETING_HELP`,
    [FilterTypes.PROGRAMMATIC_TV_CHANNEL]: $localize`:@@PROGRAMMATIC_TV_CHANNEL_FILTER_HELP:PROGRAMMATIC_TV_CHANNEL_FILTER_HELP`,
    [FilterTypes.PROVIDER]: $localize`:@@PROVIDER_FILTER_HELP:PROVIDER_FILTER_HELP`,
    [FilterTypes.SSP]: $localize`:@@SSP_TARGETING_HELP:SSP_TARGETING_HELP`,
    [FilterTypes.STATE]: $localize`:@@STATE_TARGETING_HELP:STATE_TARGETING_HELP`,
    [FilterTypes.TAGGING]: $localize`:@@TAGGING_TARGETING_HELP:TAGGING_TARGETING_HELP`,
    [FilterTypes.TIME]: $localize`:@@TIME_TARGETING_HELP:TIME_TARGETING_HELP`,
    [FilterTypes.TV_CHANNEL]: $localize`:@@TV_CHANNEL_FILTER_HELP:TV_CHANNEL_FILTER_HELP`,
    [FilterTypes.TV_TARGETING_GROUP]: $localize`:@@TV_TARGETING_GROUP_FILTER_HELP:TV_TARGETING_GROUP_FILTER_HELP`,
    [FilterTypes.URL]: $localize`:@@URL_TARGETING_HELP:URL_TARGETING_HELP`,
    [FilterTypes.VIDEO_PLAYER]: $localize`:@@VIDEO_PLAYER_FILTER_HELP:VIDEO_PLAYER_FILTER_HELP`,
    [FilterTypes.VIDEO_POSITION]: $localize`:@@VIDEO_POSITION_FILTER_HELP:VIDEO_POSITION_FILTER_HELP`,
    [FilterTypes.VISIBILITY]: $localize`:@@VISIBILITY_TARGETING_HELP:VISIBILITY_TARGETING_HELP`,
    [FilterTypes.WEATHER]: $localize`:@@WEATHER_FILTER_HELP:WEATHER_FILTER_HELP`,
    [FilterTypes.WIFI]: $localize`:@@WIFI_FILTER_HELP:WIFI_FILTER_HELP`,
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: $localize`:@@WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_HELP:WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_HELP`,
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: $localize`:@@WEISCHER_AUDIENCES_TRACE_FILTER_HELP:WEISCHER_AUDIENCES_TRACE_FILTER_HELP`,
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: $localize`:@@WEISCHER_AUDIENCES_CUSTOM_FILTER_HELP:WEISCHER_AUDIENCES_CUSTOM_FILTER_HELP`,
};

const SELECTION_HELP_TRANSLATIONS: Partial<Record<FilterTypes, string>> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]:
        parseMarkdown($localize`:@@ADSQUARE_GEO_FILTER_SELECTION_HELP:ADSQUARE_GEO_FILTER_SELECTION_HELP`),
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]:
        parseMarkdown($localize`:@@CUSTOM_SITE_CONTEXTUAL_FILTER_SELECTION_HELP:CUSTOM_SITE_CONTEXTUAL_FILTER_SELECTION_HELP`),
    [FilterTypes.DOMAIN]: $localize`:@@DOMAIN_TARGETING_SELECTION_HELP:DOMAIN_TARGETING_SELECTION_HELP`,
    [FilterTypes.DOOH_LOCATION]: parseMarkdown($localize`:@@DOOH_LOCATION_FILTER_SELECTION_HELP:DOOH_LOCATION_FILTER_SELECTION_HELP`),
    [FilterTypes.IAS_APP_CONTEXTUAL]: parseMarkdown($localize`:@@IAS_FILTER_APP_SELECTION_HELP:IAS_FILTER_APP_SELECTION_HELP`),
    [FilterTypes.IAS_SITE_CONTEXTUAL]: parseMarkdown($localize`:@@IAS_FILTER_WEB_SELECTION_HELP:IAS_FILTER_WEB_SELECTION_HELP`),
    [FilterTypes.OTTO_PNTA_CUSTOM]: parseMarkdown($localize`:@@OTTO_PNTA_FILTER_SELECTION_HELP:OTTO_PNTA_FILTER_SELECTION_HELP`),
    [FilterTypes.OTTO_PNTA_GEO]: parseMarkdown($localize`:@@OTTO_PNTA_FILTER_SELECTION_HELP:OTTO_PNTA_FILTER_SELECTION_HELP`),
    [FilterTypes.OTTO_PNTA_INTENT]: parseMarkdown($localize`:@@OTTO_PNTA_FILTER_SELECTION_HELP:OTTO_PNTA_FILTER_SELECTION_HELP`),
    [FilterTypes.OTTO_PNTA_PURCHASE]: parseMarkdown($localize`:@@OTTO_PNTA_FILTER_SELECTION_HELP:OTTO_PNTA_FILTER_SELECTION_HELP`),
    [FilterTypes.OTTO_PNTA_SOZIO]: parseMarkdown($localize`:@@OTTO_PNTA_FILTER_SELECTION_HELP:OTTO_PNTA_FILTER_SELECTION_HELP`),
    [FilterTypes.POLYGON]: parseMarkdown($localize`:@@POLYGON_FILTER_SELECTION_HELP:POLYGON_FILTER_SELECTION_HELP`),
    [FilterTypes.POSTAL_CODE]: parseMarkdown($localize`:@@POSTAL_CODE_FILTER_SELECTION_HELP:POSTAL_CODE_FILTER_SELECTION_HELP`),
    [FilterTypes.TAGGING]: $localize`:@@TAGGING_TARGETING_SELECTION_HELP:TAGGING_TARGETING_SELECTION_HELP`,
    [FilterTypes.TIME]: $localize`:@@TIME_TARGETING_SELECTION_HELP:TIME_TARGETING_SELECTION_HELP`,
    [FilterTypes.URL]: $localize`:@@URL_TARGETING_SELECTION_HELP:URL_TARGETING_SELECTION_HELP`,
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: parseMarkdown(
        $localize`:@@WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_SELECTION_HELP:WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_SELECTION_HELP`,
    ),
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: parseMarkdown(
        $localize`:@@WEISCHER_AUDIENCES_TRACE_FILTER_SELECTION_HELP:WEISCHER_AUDIENCES_TRACE_FILTER_SELECTION_HELP`,
    ),
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: parseMarkdown(
        $localize`:@@WEISCHER_AUDIENCES_CUSTOM_FILTER_SELECTION_HELP:WEISCHER_AUDIENCES_CUSTOM_FILTER_SELECTION_HELP`,
    ),
};

const INFO_BOX_TRANSLATIONS: Partial<Record<FilterTypes, string>> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]: $localize`:@@ADSQUARE_GEO_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ADSQUARE_GEO_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]: $localize`:@@CUSTOM_SITE_CONTEXTUAL_FILTER_BILLABLE_CPM_INFO_MESSAGE:CUSTOM_SITE_CONTEXTUAL_FILTER_BILLABLE_CPM_INFO_MESSAGE`,
    [FilterTypes.IAS_APP_CONTEXTUAL]: parseMarkdown($localize`:@@IAS_FILTER_INFO_BOX_MESSAGE:IAS_FILTER_INFO_BOX_MESSAGE`),
    [FilterTypes.IAS_SITE_CONTEXTUAL]: parseMarkdown($localize`:@@IAS_FILTER_INFO_BOX_MESSAGE:IAS_FILTER_INFO_BOX_MESSAGE`),
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: $localize`:@@ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: $localize`:@@ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: $localize`:@@ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: $localize`:@@ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: $localize`:@@ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE:ODC_CONTEXTUAL_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.OTTO_PNTA_CUSTOM]: $localize`:@@OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE:OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.OTTO_PNTA_GEO]: $localize`:@@OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE:OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.OTTO_PNTA_INTENT]: $localize`:@@OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE:OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.OTTO_PNTA_PURCHASE]: $localize`:@@OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE:OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.OTTO_PNTA_SOZIO]: $localize`:@@OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE:OTTO_PNTA_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.TAGGING]: $localize`:@@TAGGING_FILTER_INFO_BOX_MESSAGE:TAGGING_FILTER_INFO_BOX_MESSAGE`,
    [FilterTypes.TV_CHANNEL]:
        parseMarkdown($localize`:@@TV_CHANNEL_FILTER_INFO_BOX_HINT_MESSAGE:TV_CHANNEL_FILTER_INFO_BOX_HINT_MESSAGE`),
    [FilterTypes.TV_TARGETING_GROUP]: $localize`:@@TARGETING_GROUP_TARGETING_NOT_AVAILABLE_FOR_RTL_PUBLISHER_INFO_MESSAGE:TARGETING_GROUP_TARGETING_NOT_AVAILABLE_FOR_RTL_PUBLISHER_INFO_MESSAGE`,
    [FilterTypes.WEATHER]: $localize`:@@WEATHER_TARGETING_NOT_AVAILABLE_FOR_RTL_PUBLISHER_INFO_MESSAGE:WEATHER_TARGETING_NOT_AVAILABLE_FOR_RTL_PUBLISHER_INFO_MESSAGE`,
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: $localize`:@@WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_INFO_BOX_HINT_MESSAGE:WEISCHER_AUDIENCES_DOOH_TV_BOOST_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: $localize`:@@WEISCHER_AUDIENCES_TRACE_FILTER_INFO_BOX_HINT_MESSAGE:WEISCHER_AUDIENCES_TRACE_FILTER_INFO_BOX_HINT_MESSAGE`,
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: $localize`:@@WEISCHER_AUDIENCES_CUSTOM_FILTER_INFO_BOX_HINT_MESSAGE:WEISCHER_AUDIENCES_CUSTOM_FILTER_INFO_BOX_HINT_MESSAGE`,
};

const ICONS: Record<FilterTypesForTranslations, IIcon> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]: {name: 'adsquare', font: 'vm-icons'},
    [FilterTypes.AUCTION_TYPE]: {name: 'gavel'},
    [FilterTypes.AUDIO]: {name: 'audiotrack'},
    [FilterTypes.BROWSER]: {name: 'web'},
    [FilterTypes.CAPPING]: {name: 'update'},
    [FilterTypes.CITY]: {name: 'location_city'},
    [FilterTypes.CLUSTER]: {name: 'format_list_bulleted'},
    [FilterTypes.CONTENT_CATEGORY]: {name: 'style'},
    [FilterTypes.COUNTRY]: {name: 'map'},
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]: {name: 'vpn_key'},
    [FilterTypes.DOMAIN]: {name: 'public'},
    [FilterTypes.DOOH_LOCATION]: {name: 'place'},
    [FilterTypes.GRAPH_CAPPING]: {name: 'update'},
    [FilterTypes.IAS_APP_CONTEXTUAL]: {name: 'ias', font: 'vm-icons'},
    [FilterTypes.IAS_SITE_CONTEXTUAL]: {name: 'ias', font: 'vm-icons'},
    [FilterTypes.INTERSTITIAL]: {name: 'view_carousel'},
    [FilterTypes.LANGUAGE]: {name: 'language'},
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: {name: 'oracle', font: 'vm-icons'},
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: {name: 'oracle', font: 'vm-icons'},
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: {name: 'oracle', font: 'vm-icons'},
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: {name: 'oracle', font: 'vm-icons'},
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: {name: 'oracle', font: 'vm-icons'},
    [FilterTypes.OS]: {name: 'desktop_windows'},
    [FilterTypes.OTTO_PNTA_CUSTOM]: {name: 'otto_pnta', font: 'vm-icons'},
    [FilterTypes.OTTO_PNTA_GEO]: {name: 'otto_pnta', font: 'vm-icons'},
    [FilterTypes.OTTO_PNTA_INTENT]: {name: 'otto_pnta', font: 'vm-icons'},
    [FilterTypes.OTTO_PNTA_PURCHASE]: {name: 'otto_pnta', font: 'vm-icons'},
    [FilterTypes.OTTO_PNTA_SOZIO]: {name: 'otto_pnta', font: 'vm-icons'},
    [FilterTypes.PLATFORM]: {name: 'devices'},
    [FilterTypes.POLYGON]: {name: 'polygon', font: 'vm-icons'},
    [FilterTypes.POSTAL_CODE]: {name: 'home'},
    [FilterTypes.PROFILE]: {name: 'code'},
    [FilterTypes.PROGRAMMATIC_TV_CHANNEL]: {name: 'live_tv'},
    [FilterTypes.PROVIDER]: {name: 'router'},
    [FilterTypes.SSP]: {name: 'business'},
    [FilterTypes.STATE]: {name: 'place'},
    [FilterTypes.TAGGING]: {name: 'format_list_bulleted'},
    [FilterTypes.TIME]: {name: 'alarm'},
    [FilterTypes.TV_CHANNEL]: {name: 'live_tv'},
    [FilterTypes.TV_TARGETING_GROUP]: {name: 'group'},
    [FilterTypes.URL]: {name: 'public'},
    [FilterTypes.VIDEO_PLAYER]: {name: 'ondemand_video'},
    [FilterTypes.VIDEO_POSITION]: {name: 'ondemand_video'},
    [FilterTypes.VISIBILITY]: {name: 'visibility'},
    [FilterTypes.WEATHER]: {name: 'cloud'},
    [FilterTypes.WIFI]: {name: 'wifi'},
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: {name: 'track_changes'},
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: {name: 'track_changes'},
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: {name: 'track_changes'},
};

const subModuleSuffixes: Record<FilterTypesForTranslations, string> = {
    [FilterTypes.ADSQUARE_GEO_CONTEXTUAL]: 'adsquare-geo',
    [FilterTypes.AUCTION_TYPE]: 'auction-type',
    [FilterTypes.AUDIO]: 'audio',
    [FilterTypes.BROWSER]: 'browser',
    [FilterTypes.CAPPING]: 'capping',
    [FilterTypes.CITY]: 'city',
    [FilterTypes.CLUSTER]: 'cluster',
    [FilterTypes.CONTENT_CATEGORY]: 'content-category',
    [FilterTypes.COUNTRY]: 'country',
    [FilterTypes.CUSTOM_SITE_CONTEXTUAL]: 'custom-site-contextual',
    [FilterTypes.DOMAIN]: 'domain',
    [FilterTypes.DOOH_LOCATION]: 'dooh-location',
    [FilterTypes.GRAPH_CAPPING]: 'graph-capping',
    [FilterTypes.IAS_APP_CONTEXTUAL]: 'ias-app',
    [FilterTypes.IAS_SITE_CONTEXTUAL]: 'ias-web',
    [FilterTypes.INTERSTITIAL]: 'interstitial',
    [FilterTypes.LANGUAGE]: 'language',
    [FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL]: 'odc-brand-safety-contextual',
    [FilterTypes.ODC_CONTEXT_CONTEXTUAL]: 'odc-context-contextual',
    [FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL]: 'odc-invalid-traffic-contextual',
    [FilterTypes.ODC_LANGUAGE_CONTEXTUAL]: 'odc-language-contextual',
    [FilterTypes.ODC_VIEWABILITY_CONTEXTUAL]: 'odc-viewability-contextual',
    [FilterTypes.OS]: 'os',
    [FilterTypes.OTTO_PNTA_CUSTOM]: 'otto-pnta-custom',
    [FilterTypes.OTTO_PNTA_GEO]: 'otto-pnta-geo',
    [FilterTypes.OTTO_PNTA_INTENT]: 'otto-pnta-intent',
    [FilterTypes.OTTO_PNTA_PURCHASE]: 'otto-pnta-purchase',
    [FilterTypes.OTTO_PNTA_SOZIO]: 'otto-pnta-sozio',
    [FilterTypes.PLATFORM]: 'platform',
    [FilterTypes.POLYGON]: 'polygon',
    [FilterTypes.POSTAL_CODE]: 'postal-code',
    [FilterTypes.PROFILE]: 'profile',
    [FilterTypes.PROGRAMMATIC_TV_CHANNEL]: 'programmatic-tv-channel',
    [FilterTypes.PROVIDER]: 'provider',
    [FilterTypes.SSP]: 'ssp',
    [FilterTypes.STATE]: 'state',
    [FilterTypes.TAGGING]: 'tagging',
    [FilterTypes.TIME]: 'time',
    [FilterTypes.TV_CHANNEL]: 'tv-channel',
    [FilterTypes.TV_TARGETING_GROUP]: 'tv',
    [FilterTypes.URL]: 'url',
    [FilterTypes.VIDEO_PLAYER]: 'video-player',
    [FilterTypes.VIDEO_POSITION]: 'video-position',
    [FilterTypes.VISIBILITY]: 'visibility',
    [FilterTypes.WEATHER]: 'weather',
    [FilterTypes.WIFI]: 'wifi',
    [FilterTypes.WEISCHER_AUDIENCES_DOOH_TV_BOOST]: 'weischer-audiences-dooh-tv-boost',
    [FilterTypes.WEISCHER_AUDIENCES_CUSTOM]: 'weischer-audiences-custom',
    [FilterTypes.WEISCHER_AUDIENCES_TRACE]: 'weischer-audiences-trace',
};

function getUrlSuffixForFilterType(type: string | FilterTypes): string {
    if (subModuleSuffixes[type]) {
        return subModuleSuffixes[type];
    }

    throw new CommonsError('no url suffix found for given type', {data: {type}});
}

function matchTypeForUrlSuffix(suffix: string): FilterTypes | undefined {
    let filterType: keyof typeof subModuleSuffixes;
    for (filterType in subModuleSuffixes) {
        if (suffix === subModuleSuffixes[filterType]) {
            return filterType;
        }
    }
}

function getTypeForUrlSuffix(suffix: string): FilterTypes {
    const filterType: FilterTypes | undefined = matchTypeForUrlSuffix(suffix);

    if (filterType) {
        return filterType;
    }

    throw new CommonsError('no filter type found for given suffix', {data: {suffix}});
}

/**
 * returns the translation of the given id
 *
 * @param {String} filterType id of the translation
 * @param {Boolean} [inventoried=false] fetch type for inventoried
 *
 * @returns {String} returns the translation as a string
 */
function getFilterTypeTranslation(filterType: FilterTypes, inventoried: boolean = false): string {
    const translation: string | undefined = _getTranslation(
        TYPE_TRANSLATIONS,
        TYPE_TRANSLATIONS_INVENTORIED,
        filterType,
        inventoried,
    );

    if (!translation) {
        throw new CommonsError('type translation for requested filter type does not exist', {
            data: {
                filterType,
                inventoried,
            },
        });
    }

    return translation;
}

function getFilterTypeTranslationForNavigation(filterType: FilterTypes): string {
    const translation: string | undefined = _getTranslation(
        TYPE_TRANSLATIONS_NAVIGATION,
        {},
        filterType,
        false,
    );
    if (!translation) {
        return getFilterTypeTranslation(filterType);
    }

    return translation;
}

function getFilterTypeTranslationForPreview(filterType: FilterTypes, inventoried: boolean = false): string {
    const translation: string | undefined = _getTranslation(
        TYPE_TRANSLATIONS_PREVIEW,
        TYPE_TRANSLATIONS_PREVIEW_INVENTORIED,
        filterType,
        false,
    );

    if (!translation) {
        return getFilterTypeTranslation(filterType, inventoried);
    }

    return translation;
}

/**
 * returns the translation as a string
 */
function getFilterHelpTranslation(filterType: FilterTypes): string {
    const translation: string | undefined = HELP_TRANSLATIONS[filterType];
    if (!translation) {
        throw new CommonsError('help translation for requested filter type does not exist', {
            data: {filterType},
        });
    }

    return translation;
}

function getFilterSelectionHelpTranslation(filterType: FilterTypes): string {
    const translation: string | undefined = SELECTION_HELP_TRANSLATIONS[filterType];
    if (!translation) {
        throw new CommonsError(
            'selection help translation for requested filter type does not exist',
            {data: {filterType}},
        );
    }

    return translation;
}

function getFilterInfoBoxTranslation(filterType: FilterTypes): string {
    const translation: string | undefined = INFO_BOX_TRANSLATIONS[filterType];
    if (!translation) {
        throw new CommonsError(
            'info box translation for requested filter type does not exist',
            {data: {filterType}},
        );
    }

    return translation;
}
function _getTranslation(
    normalTranslations: Partial<Record<FilterTypes, string>>,
    inventoriedTranslations: Partial<Record<FilterTypes, string>>,
    filterType: FilterTypes,
    inventoried: boolean,
): string | undefined {
    if (inventoried && inventoriedTranslations[filterType]) {
        return inventoriedTranslations[filterType];
    }

    return normalTranslations[filterType];
}

function getFilterIconOf(filterType: FilterTypes): IIcon {
    const icon: IIcon | undefined = ICONS[filterType];
    if (!icon) {
        throw new CommonsError(
            'icon for requested filter type is not defined',
            {data: {filterType}},
        );
    }

    return icon;
}

function getFilterHeadlineIconSource(filterType: FilterTypes): string {
    switch (filterType) {
        case FilterTypes.OTTO_PNTA_GEO:
        case FilterTypes.OTTO_PNTA_SOZIO:
        case FilterTypes.OTTO_PNTA_INTENT:
        case FilterTypes.OTTO_PNTA_PURCHASE:
        case FilterTypes.OTTO_PNTA_CUSTOM:
            return 'assets/colour-icons/otto-pnta-colour.svg';
        case FilterTypes.IAS_SITE_CONTEXTUAL:
        case FilterTypes.IAS_APP_CONTEXTUAL:
            return 'assets/colour-icons/ias-colour.svg';
        case FilterTypes.ADSQUARE_GEO_CONTEXTUAL:
            return 'assets/colour-icons/adsquare-colour.svg';
        case FilterTypes.ODC_VIEWABILITY_CONTEXTUAL:
        case FilterTypes.ODC_LANGUAGE_CONTEXTUAL:
        case FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL:
        case FilterTypes.ODC_CONTEXT_CONTEXTUAL:
        case FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL:
            return 'assets/colour-icons/oracle-colour.svg';
        default:
            return '';
    }
}

export {
    getFilterIconOf,
    getFilterTypeTranslation,
    getFilterHeadlineIconSource,
    getFilterHelpTranslation,
    getFilterSelectionHelpTranslation,
    getFilterInfoBoxTranslation,
    getFilterTypeTranslationForPreview,
    getUrlSuffixForFilterType,
    getTypeForUrlSuffix,
    getFilterTypeTranslationForNavigation,
    matchTypeForUrlSuffix,
};
