import {Observable, of} from 'rxjs';
import {ContextualFilter, IContextualFilterProperties} from '../types/contextual-filter';
import {FilterTypes} from '../types/filter.types';

type IasFilterProperties = IContextualFilterProperties;

abstract class IasFilter<TFilterType extends FilterTypes = FilterTypes> extends ContextualFilter<TFilterType> {
    protected readonly _isMultiFilter: boolean = false;
    protected readonly _isAvailableAsBidFactor: boolean = false;
    protected readonly _isInventoriable: boolean = true;
    protected readonly _isInvertible: boolean = false;
    protected abstract props: IasFilterProperties;

    public isValid(): Observable<boolean> {
        if (!this.expression.length) {
            return of(false);
        }

        return of(true);
    }

    public canBeInventoried(): Observable<boolean> {
        return of(this.isInventoriable());
    }

    public isInverted(): Observable<boolean> {
        return of(false);
    }
}

export {IasFilter, IasFilterProperties};
