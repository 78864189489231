import {InjectionToken} from '@angular/core';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Observable, of} from 'rxjs';

interface IHttpParam {
    key: string;
    value: string | number | boolean | Array<string | number>;
}

interface IHttpHeader {
    key: string;
    value: string;
}
const API_URL: InjectionToken<string> = new InjectionToken<string>('api.domain');

function catch403(error: HttpErrorResponse): Observable<[]> {
    if (error.status === HttpStatusCode.Forbidden) {
        return of([]);
    }

    throw error;
}

function catch404(error: HttpErrorResponse): Observable<[]> {
    if (error.status === HttpStatusCode.NotFound) {
        return of([]);
    }

    throw error;
}

export {IHttpParam, IHttpHeader, API_URL, catch403, catch404};
