import {ICloneable, IMergable} from '../types';
import {UserPermission} from './user-permission';

class User implements IUser, ICloneable<User>, IMergable<User> {
    private readonly _id: number | null;
    private _name: string;
    private _externalIds: Array<number> = [];
    private _permissions: Array<UserPermission> = [];
    private _group: UserGroup;
    private _firstName: string | null = null;
    private _surname: string | null = null;
    private _gender: Gender | null = null;
    private _receiveNewsletter: boolean = false;
    private _passwordExpiryDate: string | undefined;
    private _lastUpdate: number | undefined;
    private _deleted: boolean = false;

    constructor(id: number | null, name: string, group: UserGroup) {
        this._id = id;
        this._name = name;
        this._group = group;
    }

    get id(): number | null {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get externalIds(): Array<number> {
        return this._externalIds;
    }

    set externalIds(value: Array<number>) {
        this._externalIds = value;
    }

    get permissions(): Array<UserPermission> {
        return this._permissions;
    }

    set permissions(value: Array<UserPermission>) {
        this._permissions = value;
    }

    get group(): UserGroup {
        return this._group;
    }

    set group(value: UserGroup) {
        this._group = value;
    }

    get passwordExpiryDate(): string | undefined {
        return this._passwordExpiryDate;
    }

    set passwordExpiryDate(value: string | undefined) {
        this._passwordExpiryDate = value;
    }

    get firstName(): string | null {
        return this._firstName;
    }

    set firstName(value: string | null) {
        this._firstName = value;
    }

    get surname(): string | null {
        return this._surname;
    }

    set surname(value: string | null) {
        this._surname = value;
    }

    get gender(): Gender | null {
        return this._gender;
    }

    set gender(value: Gender | null) {
        this._gender = value;
    }

    get receiveNewsletter(): boolean {
        return this._receiveNewsletter;
    }

    set receiveNewsletter(value: boolean) {
        this._receiveNewsletter = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    public canRead(): boolean {
        return this._permissions.includes(UserPermission.Read);
    }

    public canCreate(): boolean {
        return this._permissions.includes(UserPermission.Create);
    }

    public canUpdate(): boolean {
        return this._permissions.includes(UserPermission.Update);
    }

    public canDelete(): boolean {
        return this._permissions.includes(UserPermission.Delete);
    }

    public isAdmin(): boolean {
        return this.group === UserGroup.Admin;
    }

    public clone(): User {
        const newModel: User = new User(this.id, this.name, this.group);
        newModel.merge(this);

        return newModel;
    }

    public merge(source: User): void {
        this.name = source.name;
        this.group = source.group;
        this.firstName = source.firstName;
        this.surname = source.surname;
        this.gender = source.gender;
        this.receiveNewsletter = source.receiveNewsletter;
        this.permissions = source.permissions.map((permission: UserPermission) => {
            return permission;
        });
        this.externalIds = source.externalIds.map((externalId: number) => {
            return externalId;
        });
        this.passwordExpiryDate = source.passwordExpiryDate;
        this.lastUpdate = source.lastUpdate;
        this.deleted = source.deleted;
    }
}

enum UserGroup {
    Network = 'NETWORK',
    Admin = 'ADMIN',
}

interface IUser {
    id: number | null;
    group: string;
    name: string;
    permissions: Array<UserPermission>;
    externalIds: Array<number>;
    passwordExpiryDate: string | undefined;
    lastUpdate?: number;
    deleted?: boolean;
}

enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
    NonBinary = 'NON_BINARY',
}

export {User, UserGroup, Gender, IUser};
