
<core-info-box [level]="errorInfoStatus" class="deleted-segment-info-container">
    <ng-container core-info-box-title><span i18n="@@DELETED_SEGMENT_LIST_INFO_CONTAINER_SUBTITLE">DELETED_SEGMENT_LIST_INFO_CONTAINER_SUBTITLE</span></ng-container>
    <ng-container core-info-box-description>
        <div class="vm-flex-100 vm-flex-direction-column vm-gap-05">
            <span i18n="@@DELETED_SEGMENT_LIST_INFO_CONTAINER_DESCRIPTION">DELETED_SEGMENT_LIST_INFO_CONTAINER_DESCRIPTION</span>

            <libs-segment-list
                [segments]="deletedSegments"
                [isDisabled]="isDisabled || isReadOnly"
                [activeExternalSegmentIdsSubject]="deletedExternalSegmentIdsSubject"
                [showSearchToolbar]="false"
                [isSelectable]="false"
            ></libs-segment-list>

            <div *ngIf="!isDisabled && !isReadOnly" class="vm-flex vm-layout-justify-end">
                <button
                    (click)="clearDeletedExternalSegmentIds()"
                    mat-raised-button
                    color="warn"
                    i18n="DELETED_SEGMENT_LIST_REMOVE_ALL_BUTTON_LABEL"
                >DELETED_SEGMENT_LIST_REMOVE_ALL_BUTTON_LABEL</button>
            </div>
        </div>
    </ng-container>
</core-info-box>
