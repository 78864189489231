import {IBannerSize} from '../banner/banner';
import {BannerTypes} from '../banner/banner-types';
import {Ssps} from '../ssp/ssps';

enum DspReportDimensions {
    AgencyId = 'AGENCY_ID',
    AgencyName = 'AGENCY_NAME',
    NetworkId = 'NETWORK_ID',
    AdvertiserId = 'ADVERTISER_ID',
    AdvertiserName = 'ADVERTISER_NAME',
    AdSlotId = 'AD_SLOT_ID',
    BannerId = 'BANNER_ID',
    BannerName = 'BANNER_NAME',
    BannerSize = 'BANNER_SIZE',
    BannerType = 'BANNER_TYPE',
    CampaignId = 'CAMPAIGN_ID',
    CampaignName = 'CAMPAIGN_NAME',
    ContentUnitId = 'CONTENTUNIT_ID',
    Date = 'DATE',
    Day = 'DAY',
    Deal = 'DEAL',
    DealName = 'DEAL_NAME',
    Hour = 'HOUR',
    Month = 'MONTH',
    OrderId = 'ORDER_ID',
    OrderName = 'ORDER_NAME',
    Referrer = 'REFERRER',
    Ssp = 'SSP',
    SupplyPartnerId = 'SUPPLY_PARTNER_ID',
    TrafficType = 'TRAFFIC_TYPE',
    Year = 'YEAR',
    DoohLocationId = 'DOOH_LOCATION_ID',
    DoohLocationName = 'DOOH_LOCATION_NAME',
    DoohLocationLatitude = 'DOOH_LOCATION_LATITUDE',
    DoohLocationLongitude = 'DOOH_LOCATION_LONGITUDE',
    DoohLocationCountryCode = 'DOOH_LOCATION_COUNTRY_CODE',
    DoohLocationState = 'DOOH_LOCATION_STATE',
    DoohLocationCity = 'DOOH_LOCATION_CITY',
    DoohLocationPostCode = 'DOOH_LOCATION_POST_CODE',
    DoohLocationAddress = 'DOOH_LOCATION_ADDRESS',
    DoohLocationPublisher = 'DOOH_LOCATION_PUBLISHER',
    DoohLocationNetwork = 'DOOH_LOCATION_NETWORK',
    DoohLocationVenueType = 'DOOH_LOCATION_VENUE_TYPE',
    DoohLocationScreenCount = 'DOOH_LOCATION_SCREEN_COUNT',
}

enum DspReportMetrics {
    Count = 'COUNT',
    WonCount = 'WON_COUNT',
    BidSum = 'BID_SUM',
    AuctionPriceSum = 'AUCTION_PRICE_SUM',
    Clicks = 'CLICKS',
    NetCost = 'NET_COST',
    TechFee = 'TECH_FEE',
    AdvertiserFee = 'ADVERTISER_FEE',
    ThirdPartyCosts = 'THIRD_PARTY_COSTS',
    TaggingCosts = 'TAGGING_COSTS',
    IasCosts = 'IAS_COSTS',
    AdsquareCosts = 'ADSQUARE_COSTS',
    Contacts = 'CONTACTS',
    TurnoverClicks = 'TURNOVER_CLICKS',
    Turnover = 'TURNOVER',
    TurnoverViews = 'TURNOVER_VIEWS',
    PostviewTurnover = 'POSTVIEW_TURNOVER',
    EventStartCount = 'EVENT_START_COUNT',
    EventCompleteCount = 'EVENT_COMPLETE_COUNT',
    EventImpressionCount = 'EVENT_IMPRESSION_COUNT',
    EventFirstQuartileCount = 'EVENT_FIRST_QUARTILE_COUNT',
    EventThirdQuartileCount = 'EVENT_THIRD_QUARTILE_COUNT',
    EventMuteCount = 'EVENT_MUTE_COUNT',
    EventUnmuteCount = 'EVENT_UNMUTE_COUNT',
    EventPauseCount = 'EVENT_PAUSE_COUNT',
    EventRewindCount = 'EVENT_REWIND_COUNT',
    EventResumeCount = 'EVENT_RESUME_COUNT',
    EventFullscreenCount = 'EVENT_FULLSCREEN_COUNT',
    EventExpandCount = 'EVENT_EXPAND_COUNT',
    EventCollapseCount = 'EVENT_COLLAPSE_COUNT',
    EventCloseCount = 'EVENT_CLOSE_COUNT',
    EventMidpointCount = 'EVENT_MIDPOINT_COUNT',
    Ecpm = 'ECPM',
    Ecpc = 'ECPC',
    ClickRate = 'CLICK_RATE',
    WinRate = 'WIN_RATE',
    BidCpm = 'BID_CPM',
    PrebidCosts = 'PREBID_COSTS',
    TaggingCostsApi = 'TAGGING_COSTS_API',
    Vtr = 'VTR',
    Cpa = 'CPA',
    Cpv = 'CPV',
    Cpcv = 'CPCV',
    NettoEcpm = 'NETTO_ECPM',
}

// eslint-disable-next-line @typescript-eslint/typedef
const DspReportField = {...DspReportDimensions, ...DspReportMetrics};
// eslint-disable-next-line no-redeclare
type DspReportField = DspReportDimensions | DspReportMetrics;

interface IDspReportResult {
    // dimensions
    [DspReportField.AgencyId]?: number;
    [DspReportField.AdvertiserId]?: number;
    [DspReportField.AdSlotId]?: string;
    [DspReportField.BannerId]?: number;
    [DspReportField.CampaignId]?: number;
    [DspReportField.ContentUnitId]?: number;
    [DspReportField.Date]?: string;
    [DspReportField.Day]?: number;
    [DspReportField.Deal]?: string;
    [DspReportField.Hour]?: number;
    [DspReportField.Month]?: number;
    [DspReportField.NetworkId]?: number;
    [DspReportField.OrderId]?: number;
    [DspReportField.TrafficType]?: string;
    [DspReportField.Referrer]?: string;
    [DspReportField.Ssp]?: Ssps;
    [DspReportField.SupplyPartnerId]?: string;
    [DspReportField.Year]?: number;

    // fields
    [DspReportField.Count]?: number;
    [DspReportField.WonCount]?: number;
    [DspReportField.BidSum]?: number;
    [DspReportField.AuctionPriceSum]?: number;
    [DspReportField.Clicks]?: number;
    [DspReportField.NetCost]?: number;
    [DspReportField.TechFee]?: number;
    [DspReportField.AdvertiserFee]?: number;
    [DspReportField.ThirdPartyCosts]?: number;
    [DspReportField.TaggingCosts]?: number;
    [DspReportField.IasCosts]?: number;
    [DspReportField.AdsquareCosts]?: number;
    [DspReportField.Contacts]?: number;
    [DspReportField.TurnoverClicks]?: number;
    [DspReportField.Turnover]?: number;
    [DspReportField.TurnoverViews]?: number;
    [DspReportField.PostviewTurnover]?: number;
    [DspReportField.EventStartCount]?: number;
    [DspReportField.EventCompleteCount]?: number;
    [DspReportField.EventImpressionCount]?: number;
    [DspReportField.EventFirstQuartileCount]?: number;
    [DspReportField.EventThirdQuartileCount]?: number;
    [DspReportField.EventMuteCount]?: number;
    [DspReportField.EventUnmuteCount]?: number;
    [DspReportField.EventPauseCount]?: number;
    [DspReportField.EventRewindCount]?: number;
    [DspReportField.EventResumeCount]?: number;
    [DspReportField.EventFullscreenCount]?: number;
    [DspReportField.EventExpandCount]?: number;
    [DspReportField.EventCollapseCount]?: number;
    [DspReportField.EventCloseCount]?: number;
    [DspReportField.EventMidpointCount]?: number;

    // additional fields
    [DspReportField.AgencyName]?: string;
    [DspReportField.AdvertiserName]?: string;
    [DspReportField.OrderName]?: string;
    [DspReportField.CampaignName]?: string;
    [DspReportField.BannerName]?: string;
    [DspReportField.BannerType]?: BannerTypes;
    [DspReportField.BannerSize]?: IBannerSize;
    [DspReportField.DealName]?: string;
    [DspReportField.Ecpm]?: number;
    [DspReportField.Ecpc]?: number;
    [DspReportField.ClickRate]?: number;
    [DspReportField.WinRate]?: number;
    [DspReportField.BidCpm]?: number;
    [DspReportField.PrebidCosts]?: number;
    [DspReportField.TaggingCostsApi]?: number;
    [DspReportField.Vtr]?: number;
    [DspReportField.Cpa]?: number;
    [DspReportField.Cpv]?: number;
    [DspReportField.Cpcv]?: number;
    [DspReportField.NettoEcpm]?: number;
    [DspReportField.DoohLocationId]?: number;
    [DspReportField.DoohLocationName]?: string;
    [DspReportField.DoohLocationLatitude]?: number;
    [DspReportField.DoohLocationLongitude]?: number;
    [DspReportField.DoohLocationCountryCode]?: string;
    [DspReportField.DoohLocationState]?: string;
    [DspReportField.DoohLocationCity]?: string;
    [DspReportField.DoohLocationAddress]?: string;
    [DspReportField.DoohLocationPostCode]?: string;
    [DspReportField.DoohLocationPublisher]?: string;
    [DspReportField.DoohLocationNetwork]?: string;
    [DspReportField.DoohLocationVenueType]?: string;
    [DspReportField.DoohLocationScreenCount]?: number;
}

export {
    IDspReportResult,
    DspReportField,
    DspReportDimensions,
    DspReportMetrics,
};
