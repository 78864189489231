<button
    *ngIf="showManualLink"
    type="button"
    [color]="isThemePaletteColor() ? this.color : null"
    mat-icon-button
    i18n-matTooltip
    matTooltip="MANUAL_LINK_OPEN_TOOLTIP"
    matTooltipClass="tooltip"
    aria-label="Button to redirect to manual"
    (click)="openManualLink()"
    class="manual"
    [class.manual--grey]="isGreyColor()"
>
    <mat-icon fontSet="vm-icons">manual</mat-icon>
</button>
