import {Component, Input, OnChanges} from '@angular/core';
import {LibsManualService} from './manual.service';
import {forkJoin} from 'rxjs';
import {ThemePalette} from '@angular/material/core';
import {ManualLinkKey} from './manual-link-keys.config';

@Component({
    selector: 'libs-manual',
    templateUrl: './manual.html',
    styleUrls: ['./manual.scss'],
})
class LibsManualComponent implements OnChanges {
    @Input() public key: ManualLinkKey = ManualLinkKey.HomePage;
    @Input() public color: ManualColor | null = null;
    public showManualLink: boolean = false;

    public constructor(
        private manualService: LibsManualService,
    ) {}

    public isThemePaletteColor(): boolean {
        if (!this.color || this.isGreyColor()) {
            return false;
        }

        return !!this.color;
    }

    public isGreyColor(): boolean {
        return this.color === 'grey' || this.color === null;
    }

    public ngOnChanges(): void {
        forkJoin([
            this.manualService.hasManual(),
            this.manualService.linkExists(this.key),
        ])
            .subscribe({
                next: ([hasManual, linkExists]: [boolean, boolean]) => {
                    this.showManualLink = hasManual && linkExists;
                },
            });
    }

    public openManualLink(): void {
        this.manualService
            .openPage(this.key)
            .subscribe(
                () => {},
            );
    }
}

type ManualColor = ThemePalette | 'grey';

export {LibsManualComponent, ManualColor};
