import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LibsAdBlockDetectorService} from './ad-block-detector.service';
import {ManualLinkKey, LibsManualService} from '@active-agent/manual';
import {Status} from '../status-icon/status-icon.component';

@Component({
    selector: 'libs-ad-blocker-detector',
    templateUrl: './ad-blocker-detector.component.html',
    styleUrls: ['./ad-blocker-detector.component.scss'],
})
class LibsAdBlockerDetectorComponent implements OnInit {
    @Output() public onBlockerDetected: EventEmitter<boolean> = new EventEmitter<boolean>();
    public showAdBlockerInfoMessage: boolean = false;
    public readonly infoStatus: Status = Status.Warning;

    constructor(
        private adBlockService: LibsAdBlockDetectorService,
        private manualService: LibsManualService,
    ) {}

    public ngOnInit(): void {
        this.adBlockService.isAddBlockerDetected()
            .subscribe(
                (isAddBlockerDetected: boolean) => {
                    this.showAdBlockerInfoMessage = isAddBlockerDetected;

                    if (isAddBlockerDetected) {
                        this.onBlockerDetected.emit(true);
                    }
                },
            );
    }

    public openManual(): void {
        this.manualService.openPage(ManualLinkKey.AdBlockDetected).subscribe();
    }
}

export {LibsAdBlockerDetectorComponent};
