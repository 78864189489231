import {IMergable, ICloneable} from '../../types';
import {Observable} from 'rxjs';
import {cloneDeep} from 'lodash-es';
import {FilterTypes} from './filter.types';
import {ISegmentExpression} from './targeting-segment';
import {CommonsError} from '../../error/error';

const BANNER_DEFAULT_FILTERS: Array<FilterTypes> = [
    FilterTypes.REDIRECT_CONTENT_UNIT,
];

type StaticFilter<TFilterType extends FilterTypes, TContext extends Filter<TFilterType> = Filter<TFilterType>> = new (
    id: string | null,
    networkId: number,
) => TContext;

interface IFilterProperties {
    bannerIds: Array<number>;
    campaignIds: Array<number>;
    bidFactorIds: Array<number>;
    deleted: boolean;
    inventoried: boolean;
    name?: string | undefined;
    description?: string | undefined;
    lastUpdate?: number | undefined;
}

interface ICommonsFilterService {
    evaluateExpression(expression: string, type: FilterTypes): Observable<ISegmentExpression>;
}

abstract class Filter<
    TFilterType extends FilterTypes = FilterTypes,
> implements IMergable<Filter<TFilterType>>, ICloneable<Filter<TFilterType>> {
    public readonly ['constructor']!: StaticFilter<TFilterType, this>;
    protected abstract readonly _type: TFilterType;
    protected abstract readonly _isMultiFilter: boolean;
    protected abstract readonly _isAvailableAsBidFactor: boolean;
    protected abstract readonly _isInventoriable: boolean;
    protected abstract readonly _isAvailableForPreview: boolean;
    protected abstract readonly _isInvertible: boolean;
    protected abstract props: IFilterProperties;
    private readonly _id: string | null;
    private _networkId: number;

    constructor(
        id: string | null,
        networkId: number,
    ) {
        this._id = id;
        this._networkId = networkId;
    }

    get id(): string | null {
        return this._id;
    }

    get type(): TFilterType {
        return this._type;
    }

    get inventoried(): boolean {
        return this.props.inventoried;
    }

    set inventoried(value: boolean) {
        if (!value) {
            this.props.name = undefined;
            this.props.description = undefined;
        }
        this.props.inventoried = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    set networkId(networkId: number) {
        this._networkId = networkId;
    }

    /** Indicates if a filter is invertible or not. */
    get isInvertible(): boolean {
        return this._isInvertible;
    }

    get bannerIds(): Array<number> {
        return this.props.bannerIds;
    }

    set bannerIds(value: Array<number>) {
        this.props.bannerIds = value;
    }

    get campaignIds(): Array<number> {
        return this.props.campaignIds;
    }

    set campaignIds(value: Array<number>) {
        this.props.campaignIds = value;
    }

    get bidFactorIds(): Array<number> {
        return this.props.bidFactorIds;
    }

    set bidFactorIds(value: Array<number>) {
        this.props.bidFactorIds = value;
    }

    get lastUpdate(): number | undefined {
        return this.props.lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this.props.lastUpdate = value;
    }

    get deleted(): boolean {
        return this.props.deleted;
    }

    set deleted(value: boolean) {
        this.props.deleted = value;
    }

    get name(): string | undefined {
        return this.props.name;
    }

    set name(value: string | undefined) {
        this.props.name = value;
    }

    get description(): string | undefined {
        return this.props.description;
    }

    set description(value: string | undefined) {
        this.props.description = value;
    }

    public merge(source: this): void {
        this._networkId = source._networkId;
        this.props = cloneDeep(source.props);
    }

    public clone(): this {
        const filter: this = new this.constructor(this.id, this.networkId);
        filter.merge(this);

        return filter;
    }

    public isMultiFilter(): boolean {
        return this._isMultiFilter;
    }

    public isAvailableAsBidFactor(): boolean {
        return this._isAvailableAsBidFactor;
    }

    public abstract isValid(): Observable<boolean>;

    /** Indicates if a filter is inventoriable or not. */
    public isInventoriable(): boolean {
        return this._isInventoriable;
    }

    public isAvailableForPreview(): boolean {
        return this._isAvailableForPreview;
    }

    public isFullScreenFormAvailable(): boolean {
        return false;
    }

    /**
     * Can a filter be inventoried depends on other properties e.g. inverted
     * Some filters aren't allowed to be inventoried when the filter is inverted (blocklist)
     */
    public abstract canBeInventoried(): Observable<boolean>;
    public abstract isInverted(filterService: ICommonsFilterService): Observable<boolean>;

    /** Should be reworked into single functions in each filter instance. */
    public isAvailableForTaggingFilter(): boolean {
        return ![
            FilterTypes.DOMAIN,
            FilterTypes.IAS_APP_CONTEXTUAL,
            FilterTypes.IAS_SITE_CONTEXTUAL,
            FilterTypes.ADSQUARE_GEO_CONTEXTUAL,
            FilterTypes.CUSTOM_SITE_CONTEXTUAL,
            FilterTypes.ODC_CONTEXT_CONTEXTUAL,
            FilterTypes.ODC_BRAND_SAFETY_CONTEXTUAL,
            FilterTypes.ODC_LANGUAGE_CONTEXTUAL,
            FilterTypes.ODC_INVALID_TRAFFIC_CONTEXTUAL,
            FilterTypes.ODC_VIEWABILITY_CONTEXTUAL,
            FilterTypes.DOOH_LOCATION,
            FilterTypes.OTTO_PNTA_GEO,
            FilterTypes.OTTO_PNTA_SOZIO,
            FilterTypes.OTTO_PNTA_INTENT,
            FilterTypes.OTTO_PNTA_PURCHASE,
            FilterTypes.OTTO_PNTA_CUSTOM,
        ].includes(this.type);
    }

    protected getDefaultOptions(): IFilterProperties {
        return {
            bannerIds: [],
            bidFactorIds: [],
            campaignIds: [],
            deleted: false,
            inventoried: false,
        };
    }
}

interface IOnFilterErrorEvent<T> {
    filter: T;
    error: CommonsError;
}

export {
    Filter,
    IFilterProperties,
    IOnFilterErrorEvent,
    StaticFilter,
    BANNER_DEFAULT_FILTERS,
    ICommonsFilterService,
};
