import {IasFilter, IasFilterProperties} from './ias-filter';
import {FilterTypes} from '../types/filter.types';

type IasAppFilterProperties = IasFilterProperties;

class IasAppFilter extends IasFilter<FilterTypes.IAS_APP_CONTEXTUAL> {
    protected readonly _isAvailableForPreview: boolean = true;
    protected readonly _type: FilterTypes.IAS_APP_CONTEXTUAL = FilterTypes.IAS_APP_CONTEXTUAL;
    protected readonly props: IasAppFilterProperties = this.getDefaultOptions();
}

export {IasAppFilter};

