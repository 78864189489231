import {NgModule} from '@angular/core';
import {CurrencyPipe, NgIf} from '@angular/common';
import {LibsSegmentListModule} from '../segment-list.module';
import {MatButtonModule} from '@angular/material/button';
import {LibsDeletedSegmentInfoContainerComponent} from './deleted-segment-info-container.component';
import {CoreInfoBoxComponent} from '@angular-clan/core/info-box';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        NgIf,
        CoreInfoBoxComponent,
        LibsSegmentListModule,
        MatButtonModule,
        FlexLayoutModule,
    ],
    exports: [
        LibsDeletedSegmentInfoContainerComponent,
    ],
    declarations: [
        LibsDeletedSegmentInfoContainerComponent,
    ],
    providers: [
        CurrencyPipe,
    ],
})
class LibsDeletedSegmentInfoContainerModule {}

export {LibsDeletedSegmentInfoContainerModule};
