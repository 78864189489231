enum ApiResource {
    Orders = 'orders',
    Banners = 'banners',
    Campaigns = 'campaigns',
    Companies = 'companies',
    Assets = 'assets',
    AssetFileTypes = 'asset-file-types',
    TaggingAttributes = 'tagging-attributes',
    DmpAttributes = 'dmp-attributes',
    DilAttributes = 'dil-attributes',
    AdvertiserMargins = 'advertiser-margins',
    BannerPublisherTags ='banner-publisher-tags',
    CampaignDeals = 'campaign-deals',
    CampaignPublisherTags = 'campaign-publisher-tags',
    Countries = 'countries',
    ReportingTemplates = 'reporting-templates',
    Browsers = 'browsers',
    OperatingSystems = 'operating-systems',
    Ssps = 'ssps',
    States = 'states',
    Cities = 'cities',
    Filters = 'filters',
    AssetVastSettings = 'asset-vast-settings',
    TrackingPages = 'tracking-pages',
    TrackingSpots = 'tracking-spots',
    Users = 'users',
    ExternalDeals = 'external-deals',
    Providers = 'providers',
    BannerPools = 'banner-pools',
    BidFactors = 'bid-factors',
    Segments = 'segments',
    SegmentRelations = 'segment-relations',
    PostalCodes = 'postal-codes',
    ThirdPartyCosts = 'third-party-costs',
    ThirdPartyFeaturePrices = 'third-party-feature-prices',
    FootfallStoreConfig = 'footfall-store-configs',
    FootfallScreenConfig = 'footfall-screen-configs',
    Inventory = 'inventory',
    Networks = 'networks',
    Report = 'report',
    ContentUnits = 'contentunits',
    TargetingPeculiarities = 'targeting-peculiarities',
    DilPeculiarityPrices = 'dil-peculiarity-prices',
    DilNetworks = 'dil-networks',
    DoohLocation = 'dooh-locations',
    AtvVideo = 'atv-video',
    AutoReports = 'autoreports',
    RecentFloorPrice = 'recent-floor-price',
}

enum ApiSubResource {
    Files = 'files',
    Audience = 'audience',
    FootfallReport = 'footfall-report',
    Banner = 'banners',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Campaign = 'campaigns',
    SegmentCost = 'segment-cost',
    ExpressionCost = 'expression-cost',
    ExpressionTree = 'expression-tree',
    EncodingStatus = 'encoding-status',
    Current = 'current',
    Password = 'password',
    Report = 'report',
    VastEvents = 'vast-events',
    IconFiles = 'icon-files',
    ImageFiles = 'image-files',
    ExternalImageFiles = 'external-image-files',
    ExternalIconFiles = 'external-icon-files',
    Html5ContentDownload = 'html5-content-download',
}

export {ApiResource, ApiSubResource};
