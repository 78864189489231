import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {CoreCookieService} from '@angular-clan/core';

export function libsUserLoggedInGuard(fallbackRoute: string): CanActivateFn {
    return (): boolean => {
        const cookieService: CoreCookieService = inject(CoreCookieService);
        const router: Router = inject(Router);
        const hasToken: boolean = !!cookieService.get('token');

        if (!hasToken) {
            void router.navigate([fallbackRoute], {
                queryParams: {
                    redirectTo: router.routerState.snapshot.url,
                },
            });

            return false;
        }

        return true;
    };
}
