<libs-card [noMargin]="true" class="card-widget vm-fill">
    <div class="vm-flex-direction-column vm-fill">
        <div *ngIf="title()" class="card-widget__title">{{title()}}</div>
        <mat-divider *ngIf="title()"></mat-divider>

        <div
            *ngIf="isLoading()"
            class="vm-flex flex-direction-row vm-layout-align-center vm-layout-justify-center"
        >
            <mat-progress-spinner
                mode="indeterminate"
                [diameter]="30"
                color="primary"
            ></mat-progress-spinner>
        </div>

        <div *ngIf="!isLoading()" class="vm-flex">
            <ng-content select="[libs-card-widget-content]"></ng-content>
        </div>
        <div *ngIf="footerDescription()" class="card-widget__footer-description">{{footerDescription()}}</div>
    </div>
</libs-card>
